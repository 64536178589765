import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Config from './config/config'
import VueExcelXlsx from 'vue-excel-xlsx'
import VueBlobJsonCsv from 'vue-blob-json-csv'

// // Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

axios.defaults.withCredentials = true
axios.defaults.baseURL = Config.BASE_URL

// Make BootstrapVue available throughout your project
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueExcelXlsx)
Vue.use(VueBlobJsonCsv)

Vue.config.productionTip = false

store.dispatch('auth/getUser').then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
