/* eslint-disable eqeqeq */
/* eslint-disable eqeqeq */
<template>
  <b-container fluid class="content-wrapper">
    <div id="qr-form" v-if="!showTable">
      <b-row>
        <h1>Consulta de facturas</h1>
      </b-row>

      <b-form @submit="onSubmit">
        <b-row>
          <b-col lg="4">
              <b-form-group
              label="Fecha inicial"
              :invalid-feedback="invalidStartDateFeedback"
              :state="formValidation.startDate"
            >
              <b-form-datepicker
                id="start-time"
                v-model="form.startDate"
                :state="formValidation.startDate"
                no-close-button
                placeholder=""
                locale="es"
              ></b-form-datepicker>
              </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group
              label="Fecha Final"
              :invalid-feedback="invalidEndDateFeedback"
              :state="formValidation.endDate"
            >
              <b-form-datepicker
                id="end-time"
                v-model="form.endDate"
                :state="formValidation.endDate"
                locale="es"
                placeholder=""
                no-close-button
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col lg="8" class="d-flex justify-content-end">
            <b-button type="submit" variant="primary" :disabled="formIsInvalid" >
              <b-spinner small v-if="loading"></b-spinner>
               {{ buttonText }}
              </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>

    <div id="show-data" v-else>
          <div class="downloading" v-if="downloading">
            <b-spinner  medium ></b-spinner>
          </div>

         <b-row class="mt-6 mb-3 d-flex justify-content-center">
          <div class="buttons">
             <div class="excel">
                <vue-excel-xlsx
                    :data="redeemedCodes"
                    :columns="excelColumns"
                    :file-name="'facturacion'"
                    :file-type="'xlsx'"
                    :sheet-name="'hoja1'"
                    >
                    <b-icon icon="cloud-download" aria-hidden="true"></b-icon> Excel
               </vue-excel-xlsx>

               <vue-blob-json-csv
                    file-type="csv"
                    file-name="facturacion"
                    :data="csvData"
                    :fields="csvFiels"
                  >
                  <b-icon icon="cloud-download" aria-hidden="true"></b-icon> CSV
                </vue-blob-json-csv>
             </div>

            <b-button class="another-button" variant="primary" @click="toggleTable">
                Hacer otra consulta
            </b-button>
          </div>
        </b-row>

        <b-row>

        </b-row>

        <b-row class="mt-6 mb-3 d-flex justify-content-center">
            <div id="total">
              <p> TOTAL: {{totalAmount}} € </p>
            </div>

            <b-table striped hover responsive
            :items="redeemedCodes"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            label-sort-asc=""
            label-sort-desc=""
            label-sort-clear=""
            >
            <template #cell(file)="data">
              <b-button title="Download" variant="primary" @click="downloadFile('signedXML', data.value)">
                <b-icon icon="cloud-download"></b-icon>
              </b-button>
            </template>

            <template #cell(response_file)="data">
              <b-button title="Download" variant="primary" @click="downloadFile('responseFiles', data.value)">
                <b-icon icon="cloud-download"></b-icon>
              </b-button>
            </template>

            </b-table>

            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
              class="custompagination"
            ></b-pagination>
        </b-row>
    </div>
  </b-container>
</template>

<script>
import Config from '../config/config'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'invoices',
  data () {
    return {
      csvData: [],
      redeemedCodes: {},
      fields: [
        { key: 'id', label: 'Id' },
        { key: 'amount', label: 'Importe', formatter: 'amountFormatter' },
        { key: 'vat_amount', label: 'IVA', formatter: 'amountFormatter' },
        { key: 'total', label: 'Total', formatter: 'amountFormatter', sortable: true },
        { key: 'invoice_number', label: 'N. Factura', sortable: true },
        { key: 'invoice_date', label: 'Fecha', sortable: true },
        { key: 'quantity', label: 'Correcta', sortable: true },
        { key: 'file', label: 'Factura' },
        { key: 'response_file', label: 'Respuesta' }
      // { key: 'actions', label: 'Actions' }
      ],
      perPage: 50,
      currentPage: 1,
      loading: false,
      downloading: false,
      showTable: false,
      totalAmount: 0,
      form: {
        startDate: '',
        endDate: ''
      },
      // userStationCodes: [],
      formValidation: {
        startDate: true,
        endDate: true
      },
      excelColumns: [
        { field: 'id', label: 'Id' },
        { field: 'amount', label: 'Importe' },
        { field: 'vat_amount', label: 'IVA' },
        { field: 'total', label: 'Total' },
        { field: 'invoice_number', label: 'N. Factura' },
        { field: 'invoice_date', label: 'Fecha' },
        { field: 'quantity', label: 'Correcta' }
      ],
      csvFiels: ['id', 'amount', 'vat_amount', 'total', 'invoice_number', 'invoice_date', 'quantity']
    }
  },
  computed: {
    ...mapGetters({
      userStations: 'auth/userStations',
      userPartners: 'auth/userPartners',
      user: 'auth/user'
    }),
    rows () {
      return this.redeemedCodes.length
    },
    buttonText () {
      return this.loading
        ? 'Cargando...'
        : 'Obtener datos'
    },
    invalidStartDateFeedback () {
      return 'Selecciona una fecha'
    },
    invalidEndDateFeedback () {
      return 'Selecciona una fecha'
    },
    formIsInvalid () {
      return Object.values(this.formValidation).indexOf(false) > -1 ||
         Object.values(this.formValidation).indexOf(null) > -1
    }
  },
  watch: {
    'form.startDate': function (newVal, oldVal) {
      // eslint-disable-next-line eqeqeq
      if (newVal != oldVal) {
        this.isStartDateValid()
      }
    },
    'form.endDate': function (newVal, oldVal) {
      // eslint-disable-next-line eqeqeq
      if (newVal != oldVal) {
        this.isEndDateValid()
      }
    }
  },
  methods: {
    amountFormatter (value) {
      const formatted = parseFloat(value).toFixed(2)
      return formatted
    },
    isStartDateValid () {
      this.formValidation.startDate = this.form.startDate != null
      // return this.form.expiration != this.formDefault.expiration && this.form.expiration != ''
    },
    isEndDateValid () {
      this.formValidation.endDate = this.form.endDate != null
    },
    setDefaultStartDate () {
      var today = new Date()
      today.setDate(today.getDate() - 30)
      this.form.startDate = today
    },
    setDefaultEndDate () {
      var today = new Date()
      today.setDate(today.getDate())
      this.form.endDate = today
    },
    onSubmit (event) {
      event.preventDefault()
      this.isStartDateValid()
      this.isEndDateValid()
      this.loading = true

      if (Object.values(this.formValidation).indexOf(false) > -1) {
        console.log('invalid')
      } else {
        this.formIsValid = true

        axios
          .post(`${Config.API_BASE_URL}/invoices`, this.form)
          .then((response) => {
            this.showTable = true
            this.loading = false
            this.redeemedCodes = response.data.invoices
            this.csvData = response.data.invoices
            console.log(this.redeemedCodes)
            console.log(this.csvData)

            this.totalAmount = parseFloat(response.data.amount).toFixed(2)
          })
      }
    },
    toggleTable () {
      this.showTable = false
      this.loading = false
    },
    downloadFile ($path, $file) {
      this.downloading = true

      axios
        .get(`${Config.API_BASE_URL}/download/${$path}/${$file}`, this.form)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', $file)
          document.body.appendChild(link)
          link.click()
          this.downloading = false
        })
    },
    getExcel () {

    }
  },
  mounted () {
    this.setDefaultStartDate()
    this.setDefaultEndDate()
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

#qr-form {
  .row {
    margin-bottom: 10px;

    @include tablet {
      margin-bottom: 30px;
    }
  }
}

.custom-check-boxes {
  display: flex;
  flex-wrap: wrap;
 // ::v-deep input {margin: 5px !important; }
}

.downloading {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

#boxes {
  width: 100px;
}

::v-deep input, button, select, optgroup, textarea {
    margin: 5px;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.margin-top {
  margin-top:30px;

  @include tablet {
   margin-top:0
  }
}
.sub-input {
  margin-left: 15px;
  font-size: 14px
}

#total {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px
}
.buttons {
  .btn {
    display: inline-block;
    margin: 0 20px;
  }
}

.excel {
  margin-top: 20px;
  display: inline-block;

  button, span {
    border-color: $primary;
    color: #fff;
    background-color:  $primary!important;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    border-width: 0;
  }
}

.another-button.btn-primary {
  border-color: $primary;
  color: $primary!important;
  background-color: #fff;
}

::v-deep .custompagination .page-item.active .page-link {
  background-color: $primary!important;
  border-color: $primary!important;
}
</style>
