import axios from 'axios'
import Config from '../config/config'

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    userStations: null,
    userStationsCode: [],
    authError: null
  },

  getters: {
    authenticated (state) {
      return state.authenticated
    },

    user (state) {
      return state.user
    },

    userStations (state) {
      return state.userStations
    },

    userStationsCode (state) {
      return state.userStationsCode
    },

    authError (state) {
      return state.authError
    }
  },

  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },

    SET_USER (state, value) {
      state.user = value
    },

    SET_USER_STATIONS (state, value) {
      state.userStations = value
    },

    SET_USER_STATIONS_CODE (state, value) {
      state.userStationsCode = value
    },

    SET_AUTH_ERROR (state, value) {
      state.authError = value
    }
  },

  actions: {
    async signIn ({ commit, dispatch }, credentials) {
      // console.log('base url', Config.BASE_URL)
      await axios.get(`${Config.BASE_URL}/sanctum/csrf-cookie`)
      await axios.post(`${Config.BASE_URL}/login`, credentials).then((response) => {
        console.log('User signed in!')
        return dispatch('getUser')
      }).catch((error) => {
        console.log('error', error)

        const errorMsg = error.response.status === 401 || error.response.status === 422
          ? 'Nombre de usuario y/o contraseña incorrectos'
          : 'Se ha producido un error.Por favor, inténtalo de nuevo'

        commit('SET_AUTH_ERROR', errorMsg)
      })
    },

    async signOut ({ dispatch }) {
      await axios.post(`${Config.BASE_URL}/logout`)
      return dispatch('getUser')
    },

    getUser ({ commit, dispatch }) {
      return axios.get(`${Config.BASE_URL}/api/user`).then((response) => {
        console.log('User signed in!')

        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', response.data)

        dispatch('userStations')
      }).catch((error) => {
        console.log('User NOT signed in!', error)
        // if (error.response) {
        //   console.log(error.response.data)
        //   console.log(error.response.status)
        //   console.log(error.response.headers)
        // }
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)

        const errorMsg = error.response.status === 401
          ? ''
          : 'Se ha producido un error.Por favor, inténtalo de nuevo'
        commit('SET_AUTH_ERROR', errorMsg)
      })
    },

    userStations ({ commit }) {
      return axios.get(`${Config.BASE_URL}/api/user-stations`).then((response) => {
        commit('SET_USER_STATIONS', response.data)

        const userStationsCodes = []
        response.data.forEach((station, index) => {
          userStationsCodes[index] = station.code
        })

        // console.log('UUUU', userStationsCodes)

        commit('SET_USER_STATIONS_CODE', userStationsCodes)

        // commit('SET_USER_COMPANIES', response.data)
      }).catch((error) => {
        commit('SET_USER_STATIONS', null)
        console.log(error.response)
      })
    }
  }

}
