// const API_BASE_URL = 'http://localhost:8080/api'
// const BASE_URL = 'http://localhost:8080'

const API_BASE_URL = 'https://api.tbai.step365.app/api'
const BASE_URL = 'https://api.tbai.step365.app'

export default {
  API_BASE_URL: API_BASE_URL,
  BASE_URL: BASE_URL
}
