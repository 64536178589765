<template>
  <div class="login-page">
    <b-container fluid>
      <b-row>
         <div class="col-lg-4 col-md-6 col-sm-8 mx-auto card">
           <img src="@/assets/logoBig.png" alt="logo" class="mb-4">
           <h2>TicketBai</h2>

            <b-overlay :show="showLoading" rounded="sm">
              <form action="#" class="form-group" @submit.prevent="submit">
                <input
                  class="form-control"
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Email"
                  v-model="formData.email">

                <input
                  class="form-control"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Contraseña"
                  v-model="formData.password">

                <input type="submit" class="btn btn-primary" value="Entrar" @click="showLoading = !showLoading">
              </form>
            </b-overlay>

            <div v-if="authError" class= "error">
              {{ authError }}
            </div>
         </div>
      </b-row>
    </b-container>

    <!-- <div class="text-sm leading-5">
      <a href="#">
        Has olvidado tu contraseña?
      </a>
    </div> -->
  </div>
</template>

<script>
// import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SignIn',
  computed: {
    ...mapGetters({
      authError: 'auth/authError',
      authenticated: 'auth/authenticated'
    })
  },
  data () {
    return {
      showLoading: false,
      formData: {
        email: '',
        password: ''
      }
    }
  },

  methods: {
    // login () {
    //   axios.get('http://localhost:8080/sanctum/csrf-cookie').then(response => {
    //     axios.post('http://localhost:8080/login', this.formData).then(response => {
    //       console.log('User signed in!')
    //     }).catch(error => console.log(error)) // credentials didn't match
    //   })
    // }

    ...mapActions({
      signIn: 'auth/signIn'
    }),
    async submit () {
      await this.signIn(this.formData)
      if (this.authenticated) {
        this.$router.replace({ name: 'home' })
      }

      this.showLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
   @import "@/assets/styles/_variables.scss";
  .card {
    background-clip: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    max-width: 350px;
    padding: 20px;
  }

  .form-group {
    input {
        margin-bottom: 20px;
    }
  }

  .login-page {
    align-items: center;
    display: flex;
    height: 100vh;

    background: url(../assets/loginbg.jpg)
      no-repeat center center;
    background-size: cover;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  h1 {
      margin-bottom: 1.5rem;
  }

  .btn-primary {
    background-color: $primary;
  }

  h2 {
    text-align: center;
  }

</style>
