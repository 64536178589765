<template>
  <div id="app">
    <b-container fluid class="p-0">
      <b-nav v-if="authenticated">
        <div class="links item">
          <b-button v-b-toggle.sidebar-no-header>
            <b-icon icon="justify" scale="2"></b-icon>
          </b-button>

           <!-- <router-link class="nav-link" to="/qr"> Generar QR</router-link>
           <router-link class="nav-link" to="/qr"> Ver QQRR canjeados</router-link> -->
        </div>

        <router-link class="nav-link item central" to="/">
            <img class="logo" src="@/assets/logo.png" alt="logo" />
        </router-link>

        <div class="user item">
          <template v-if="!authenticated">
            <router-link class="nav-link" to="/signin">Iniciar sesión</router-link> |
          </template>

          <template v-else>
            <p> {{ user.name }} </p>
            <a class="nav-link" href="#" @click.prevent="signOut">
             <b-spinner variant="light" v-if="loading"></b-spinner>
             <b-icon v-else icon="arrow-bar-right" scale="1.5"></b-icon>
            </a>
          </template>
        </div>
      </b-nav>

      <!-- to make it stay open on route change add the attribute no-close-on-route-change-->
      <b-sidebar
        v-if="authenticated"
        id="sidebar-no-header"
        bg-variant="light"
        text-variant="dark"
        shadow
        no-header
      >
         <template #default="{ hide }">
            <div class=sidebar-header>
              <span class=title> TicketBai Step365</span>

              <b-button v-b-toggle.sidebar-variant  @click="hide">
                <b-icon icon="x-circle" scale="1.5"></b-icon>
              </b-button>
            </div>

            <ul>
               <li class="nav-item">
                <router-link class="nav-link" to="/invoices">Ver facturas</router-link>
              </li>
            </ul>
         </template>
      </b-sidebar>

      <router-view />
    </b-container>
  </div>
</template>

<script>
// import axios from 'axios'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'App',
  mounted () {
    this.loading = false
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    })
  },
  methods: {
    ...mapActions({
      signOutAction: 'auth/signOut'
      // getUserStationsAction: 'auth/userStations'
    }),

    async signOut () {
      this.loading = true
      await this.signOutAction()
      this.$router.replace({ name: 'signIn' })
    }
  }
}

</script>

<style lang="scss">
@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../node_modules/bootstrap-vue/dist/bootstrap-vue.css";
@import "assets/styles/_variables.scss";

#app {
  color: $black;
  font-family: $base-font;
  font-size: 16px;
}

.content-wrapper {
  max-width: 1200px;
  padding: 32px 12px;

  @include tablet {
    margin-left: 0!important;
    padding: 24px!important;
  }

}

.btn-primary {
  color: #fff!important;
}

.error {
  color: $danger;
}

a {
  color: $primary;
  &.inverse {
    color: #fff;
  }
}

.nav, .b-sidebar-outer {
   .btn-secondary {
    color: #fff;
    background-color: $primary;
    border-color: $primary;

    &:hover {
      color: $dark;
      background-color: $primary;
      border-color: $primary;
    }

    &:active,
    &:focus,
    &:visited {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.nav {
  align-items: center;
  background-color: $primary;
  justify-content: space-between;
  height: 82px;
  padding: 4px !important;

  @include tablet {
    padding: 8px !important;
  }

  .item {
    flex: 1 1 0;

    &.central {
      text-align: center;
    }
  }

  .logo {
    height: 40px;
    width: auto;
  }

  .nav-link {
    color: #fff;

    &:hover {
      color: $dark;
    }
  }

  .links {
    align-items: center;
    display: flex;
  }

  .user {
    align-items: center;
    display: flex;
    justify-content: right;

    p{
      margin: 0;
      color: #fff;
      font-size: 14px;
    }
  }
}

.b-sidebar {
  font-family: $base-font;
  .sidebar-header {
    align-items: center;
    background-color: $primary;
    color: #fff;
    display: flex;
    height: 82px;
    justify-content: space-between;
    padding: 8px;

    .title {
      font-size: 26px;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  .nav-link {
    color: $primary;

    &:hover {
      color: $dark;
    }
  }
}
</style>
